import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Observable, catchError, throwError } from "rxjs";
import { TokenAuthService } from "./token-auth.service";
import { Injectable, inject } from "@angular/core";
import { UserInfoService } from "./user-info.service";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    constructor() { }

    // To work with the tokens and user redirects.
    private tokenAuthService = inject(TokenAuthService);
    private userService = inject(UserInfoService);

    // The main work for intercepting.
    intercept(
        req: HttpRequest<any>,
        next: HttpHandler): Observable<HttpEvent<any>> {
        // First, we only want to intercept things that are headed to our own OCISO APIs.

        // Now, are we logged in? If so, we need to insert the JWT in the request to the destination API.
        if (this.tokenAuthService.isLoggedIn()) {
            // In this case, we need to clone the request and add the bearer token.
            req = req.clone({
                setHeaders: { Authorization: 'Bearer ' + this.tokenAuthService.getAuthorizationToken() }
            });
        }

        // Now that we have a good request, we will send it but we also need to pipe a response
        // back through to ensure the token was accepted. 
        return next.handle(req).pipe(catchError(err => {
            if (this.isAuthenticationFailure(err)) {
                this.handleAuthenticationFailure(err, req);
            }
            const error = (err && err.error && err.error.message) || err.statusText;
            console.error(err);
            return throwError(() => error);
        }))
    }

    // Do what we need when authentication expires.
    private handleAuthenticationFailure(
        err: any,
        req: HttpRequest<any>) {
        this.tokenAuthService.endSession()
            .subscribe(() => {
                this.userService.userRedirect('Your session has timed out. Please sign in again to resume your work.');
            });
    }

    // Determines if there's an authenticaiton failure.
    private isAuthenticationFailure(err: any): boolean {
        return [401, 403].includes(err.status);
    }
}
