
<div *ngIf="show" class="loading-page ocisop-background">
    <mat-card class="login-card">
      <mat-card-title class="application-loading-header">Loading Components...</mat-card-title>
      <mat-divider></mat-divider>
      <div class="progress-bar-holder">
        <mat-progress-bar mode="indeterminate" color="accent" *ngIf="(isWorking)"></mat-progress-bar>
      </div>
      <mat-card-content>
        <p>Please wait a moment while the OCISO {{getAppName()}} components are loaded.</p>
      </mat-card-content>
    </mat-card>
  </div>
  
