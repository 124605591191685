import { Component, OnInit, inject } from '@angular/core';
import { NgIf } from '@angular/common';
import { TokenAuthService } from '../../services/token-auth.service';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBar } from '@angular/material/progress-bar';
import { MatCardModule } from '@angular/material/card';
import { MatDivider } from '@angular/material/divider';
import { UserInfoService } from '../../services/user-info.service';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { environment } from '../../../../../../environments/environment';
import { SsoExchange } from '../../api/identity/sso-exchange';

@Component({
  selector: 'token-entry',
  standalone: true,
  imports: [
    MatIconModule,
    MatCardModule,
    MatProgressBar,
    MatDivider,
    NgIf
  ],
  templateUrl: './token-entry.component.html',
  styleUrl: './token-entry.component.scss'
})
export abstract class TokenEntryComponent implements OnInit {

  show: boolean = false;
  done: boolean = false;
  isWorking: boolean = true;

  // We'll need the router.
  router = inject(Router);
  route = inject(ActivatedRoute);
  userService = inject(UserInfoService);

  // And we'll need the token authenticator service.
  tokenAuth = inject(TokenAuthService);

  // Things to be inherited by subcomponents.
  abstract getAppName(): string;

  constructor() { }

  ngOnInit(): void {

    setTimeout(() => this.showLoading(), 1000);

    // Before starting, we're going to start a delay after 1 second that will display a loading page.
    // That way if it takes too long, the user sees something, but if this occurs quickly, the user
    // doesn't see a short flicker.

    // For this to work, we need to:
    //   1. Get the 'redirect' and 'sub' values from the querystring
    //   2. Base64 decode the 'sub' into the user id (email)
    //   3. Connect to the identity API and retrieve the token and refresh token
    //      from the 'redirect' key
    //   4. If everything's good, set the token and load the dashboard

    // Check for parameters on the route so that we can do some work.
    this.route.queryParams.subscribe(p => {
      if (p['redirect'] && p['sub']) {
        if (environment.idp.debug) {
          console.log('redirect: ' + p['redirect'] + ', sub: ' + p['sub']);
        }
        // This should then validate that the redirect and subject are correct, followed by
        // returning the proper tokens for this user. Those should then be stored in the
        // localStorage or something for normal app usage.
        this.tokenAuth.exchangeEntry(p['redirect'], p['sub'])
          .subscribe({
            next: (response) => {
              // Okay, it was successful. Let's do the next step.
              this.successfulTokenExchange(
                response,
                this.router.getCurrentNavigation()?.extras);
            },
            error: (err) => {
              if (environment.idp.debug) {
                console.error('exchangeEntry caused an error ↓');
              }
              console.error(err);
              this.failedTokenEntry(err.message);
            }
          });
      } else {
        this.failedTokenEntry('Unable to load the ' + this.getAppName() + ' because SSO authentication tokens were not successfully provided to the application.');
      }
    });
  }

  // If token entry fails for some reason, this will be called.
  failedTokenEntry(message: string) {
    if (environment.idp.debug) {
      console.log('Entry to ' + this.getAppName() + ' failed due to missing tokens. Sending back to login.');
    }
    this.userService.userRedirect(message);
  }

  // What to do when the exchange was successful.
  successfulTokenExchange(
    response: SsoExchange,
    extras: NavigationExtras | undefined) {

    this.tokenAuth.loginWithTokens(response);

    // Once we're done, time to move on to the final destination.
    this.successfulTokenEntry(response.userIdentity, extras);
  }

  // If the whole token entry process was successful, do the final redirect.
  successfulTokenEntry(
    id: string,
    extras: NavigationExtras | undefined) {
    
    // We should also allow the token service to initialize itself.
    this.tokenAuth.beginSession(id);

    // Token granted, let's go to the dashboard.
    this.done = true;
    this.router.navigate(['/dashboard'], extras);
  }

  showLoading() {
    if (!this.done) {
      this.show = true;
    }
  }
}
