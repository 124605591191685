import { ApplicationConfig, CSP_NONCE } from '@angular/core';
import { provideRouter } from '@angular/router';
import { BASE_ROUTES } from './app.routes';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';
import { TokenInterceptor } from '../../../common-ui/src/lib/services/token-interceptor';
import { CdkColumnDef } from '@angular/cdk/table';
import { provideMarkdown } from 'ngx-markdown';

const scriptNonce = crypto.randomUUID();

export const appConfig: ApplicationConfig = {
  providers: [
    { provide: CSP_NONCE, useValue: scriptNonce },
    provideRouter(BASE_ROUTES), 
    provideAnimationsAsync(),
    /* { provide: LocationStrategy, useClass: HashLocationStrategy } */
        
    provideHttpClient(
      // fetch is a more modern API, compared to XMLHttpRequest otherwise. https://angular.dev/guide/http/setup#withfetch
      withFetch(),
      withInterceptorsFromDi()
    ),
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    CdkColumnDef,
    provideMarkdown()
  ]
};
